import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SectionTitle from '../components/SectionTitle'
import ArticleRoll from '../components/ArticleRoll'
import { ColumnistLiner } from '../components/ColumnistTemplate'

class ColumnistRoute extends React.Component {
  render() {
    const posts = this.props.data.posts
    const columnist = this.props.data.columnist.frontmatter
    const title = this.props.data.site.siteMetadata.title

    return (
      <Layout>
        <section className="section">
          <Helmet title={`${columnist.title} | ${title}`} />
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
              >
                <h2>
                  <SectionTitle>
                    <ColumnistLiner
                      title={columnist.title}
                      cover={columnist.cover}
                    />
                  </SectionTitle>
                </h2>
                <ArticleRoll allMarkdownRemark={posts} columns="2" />
                <p>
                  <Link className="primary-link" to="/columnist/">所有专栏</Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default ColumnistRoute

export const columnistPageQuery = graphql`
  query ColumnistPage($id: String, $title: String) {
    site {
      siteMetadata {
        title
      }
    }
    columnist: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title: columnist_title
        cover {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    posts: allMarkdownRemark(
      limit: 100
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          templateKey: { eq: "article" },
          columnist: { 
            frontmatter: { columnist_title: { eq: $title } }
          }
        }
      }
    ) {
      totalCount
      edges {
        node {
          ...articleThumbFields
        }
      }
    }
  }
`
